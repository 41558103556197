<template>
	<div class="page">
		<!-- 表单 -->
		<div class="huaxue_register_login_box" :style="'height:'+screenHeight">
			<div class="bgimg">
				<img src="../assets/static/login_bg.png"/>
			</div>
			<div class="contitlebox loginbox" v-show="showInput">
				<div class="title_box">
					<div class="titlename fontsize20" @click="handleLoginType('password')">
						<div class="text pointer" :class="{'hover':loginType=='password'}">密码登录</div>
						<div class="line" :class="{'hover':loginType=='password'}"></div>
					</div>
					<div class="titlename fontsize20" @click="handleLoginType('captcha')">
						<div class="text pointer" :class="{'hover':loginType=='captcha'}">短信登录</div>
						<div class="line" :class="{'hover':loginType=='captcha'}"></div>
					</div>
				</div>
				<div class="block20"></div>
				<div class="conbox fontsize14">
					<div class="inputdiv">
						<img class="icon" src="../assets/static/phoneicon.png"/>
						<div class="input"><input v-model="formObj.phone" placeholder="请输入手机号码"/></div>
					</div>
					<div class="inputdiv" v-if="loginType=='captcha'">
						<img class="icon" src="../assets/static/yzmicon.png"/>
						<div class="input"><input v-model="formObj.captcha" placeholder="请输入验证码"/></div>
						<div class="captcha" @click="getcode" v-if="!ifsetMsg">获取验证码</div>
						<div class="captcha" v-if="ifsetMsg">{{count}}s可重发</div>
					</div>
					<div class="inputdiv" v-if="loginType=='password'">
						<img class="icon" src="../assets/static/passicon.png"/>
						<div class="input"><input v-model="formObj.password" placeholder="请输入密码"/></div>
					</div>
					<div class="inputdiv1">
						<div class="inputdiv">
							<img class="icon" src="../assets/static/yzmicon.png"/>
							<div class="input"><input v-model="formObj.identifyCode" placeholder="请输入图形码"/></div>
						</div>
						<div class="coderight" @click="refreshCode">
						    <SIdentify :contentWidth="85" :contentHeight="38" :identifyCode="identifyCode"></SIdentify>
						</div>
					</div>
					<div class="block40"></div>
					<div class="btndiv fontsize16" @click="handleForm">登录</div>
					<div class="checkbox_tips fontsize12">
						<div></div>
						<!-- <el-checkbox v-model="ischecked"></el-checkbox>
						<div class="text1">自动登录</div> -->
						<div class="text2 mauto" @click="forgetPassword">忘记密码</div>
						<div class="text3" @click="jumpregister">注册新帐号</div>
					</div>
				</div>
			</div>
		</div>
		
	</div>
</template>

<script>
import {mapState,mapMutations} from 'vuex'
import { showLoading, hideLoading } from '@/common/loading.js';
import SIdentify from "@/components/identify/identify.vue"
export default {
	components:{
		SIdentify 
	},
	data() {
		return {
			showInput:false,
			loginType:"password",//账号密码 password  验证码 captcha
			screenHeight:"623px",
			formObj:{
				captcha:"",
				name:"",
				phone:"",
				password:"",
				signKey:"",
				identifyCode:"",
				loginType:0,//0密码 1短信
			},
			count: '',
			timer: null,
			ifsetMsg: false,//发送验证码
			identifyCode:"",//验证码
			identifyCodes:"1234567890",
			ischecked:false,//记得我
		};
	},
	mounted: function() {
		//获取，宽度百分百，高度是多少
		if(document.documentElement.clientWidth>1920){
			window.onresize = () => {
			  return (() => {
			    this.screenHeight = (document.documentElement.clientWidth * 623) / 1920 + "px";
			  })();
			};
		}
		if(this.isLogin){
			 this.$util.routerPath('/home')
		}else{
			this.showInput = true
			//验证码
			this.makeCode(this.identifyCodes, 4);
		}
		
	},
	computed: {
		...mapState(['userInfo', 'isLogin']),
	},
	methods: {
		...mapMutations(["SET_USERINFO",'SET_ISLOGIN']),
		//切换登录类型
		handleLoginType(type){
			this.loginType = type
		},
		forgetPassword(){
			//this.$message.info("请联系管理员修改密码")
			this.$router.push({ path: "/changePassword" });
		},
		jumpregister: function() {
		    this.$router.push({ path: "/register" });
		},
		//验证码abcdefghijklnmopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ
		randomNum(min, max) {
		  return Math.floor(Math.random() * (max - min) + min);
		},
		refreshCode() {
		  this.identifyCode = "";
		  this.makeCode(this.identifyCodes, 4);
		},
		makeCode(o, l) {
		  for (let i = 0; i < l; i++) {
		    this.identifyCode += this.identifyCodes[
		      this.randomNum(0, this.identifyCodes.length)
		    ];
		  }
		  console.log(this.identifyCode);
		},
		//登录
		handleForm() {
			var _this = this;
			var params = this.formObj
			if(this.loginType=="password"){
				if(!params.phone){
					this.$message.error("请输入手机号")
					return;
				}
				if(!params.password){
					this.$message.error("请输入密码")
					return;
				}
				if(!params.identifyCode){
					this.$message.error("请输入请输入图形码")
					return;
				}
				if(params.identifyCode!=this.identifyCode){
					this.$message.error("图形码错误")
					return;
				}
				params["loginType"] = 0
			}else{
				if(!params.phone){
					this.$message.error("请输入手机号")
					return;
				}
				if(!params.captcha){
					this.$message.error("请输入验证码")
					return;
				}
				if(!params.identifyCode){
					this.$message.error("请输入请输入图形码")
					return;
				}
				if(params.identifyCode!=this.identifyCode){
					this.$message.error("图形码错误")
					return;
				}
				params["loginType"] = 1
			}
			showLoading();
			this.$http.post('frontLogin/userLogin', params).then(function(res) {
				hideLoading();
				//数据处理
				if (res.code == 200) {
					sessionStorage.setItem('token',res.data.token);
					_this.SET_ISLOGIN(true)
					_this.SET_USERINFO(res.data.user)
					//保存
					var obj = {
						userInfo: res.data.user,
						isLogin: true,//是否登录
					}
					sessionStorage.setItem('key_state',JSON.stringify(obj))
					_this.$alert('登录成功，点击确认跳转首页', '提示', {
					    confirmButtonText: '确定',
					    callback: action => {
							if(_this.getQueryString('redirect')){
								_this.$util.routerPath(_this.getQueryString('redirect'))
							}else{
								_this.$util.routerPath('/home')
							}
					    }
					});
				} else {
					_this.$confirm(res.message, '提示', {
						confirmButtonText: '确定',
						cancelButtonText: '取消',
						type: 'warning'
					}).then(() => {}).catch(() => {});
				}
			})
		},
		//处理参数
		getQueryString(name) {
		   var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)", "i");
		   var r = window.location.search.substr(1).match(reg);
		    if (r != null) {
		        return unescape(r[2]);
		    }else{
		        return null;
		    }
		},
		//获取验证码
		getcode(){
			var _this = this
			if(this.ifsetMsg){
				console.log("不可重复发送验证码~")
				return false;
			}
			var params = this.formObj
			var re_phone = /^1(3|4|5|6|7|8|9)\d{9}$/;
			if(!re_phone.test(params.phone)){
				this.$message.error("请输入正确的手机号码")
				return;
			}
			showLoading();
			this.$http.post('frontLogin/captcha', params).then(function(res) {
				hideLoading();
				//数据处理
				if (res.code == 200) {
					_this.$message.success("发送成功~")
					_this.formObj.signKey = res.data
					//启动验证码倒计时
					_this.counttime()
				} else {
					_this.$confirm(res.message, '提示', {
						confirmButtonText: '确定',
						cancelButtonText: '取消',
						type: 'warning'
					}).then(() => {}).catch(() => {});
				}
			})
		},
		//验证码倒计时
		counttime(){
			var _this = this
			this.ifsetMsg = true
			const TIME_COUNT = 60;
			if (this.ifsetMsg) {
				this.count = TIME_COUNT;
				this.timer = setInterval(() => {
					if (_this.count > 0 && _this.count <= TIME_COUNT) {
						_this.count--;
					} else {
						clearInterval(_this.timer);
						_this.ifsetMsg = false
						_this.timer = null;
						_this.count = '';
					}
			    }, 1000);
			}
		},
		
	}
};
</script>
<style lang="scss">

</style>
